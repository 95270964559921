<template>
  <div>
    <div v-for="(value, key) in archivesData" :key="key">
      <div id="showArchives">
        <blockquote class="layui-elem-quote" style="margin: 10px 0 40px 0px">
          {{ key == "0" ? "日常杂记" : "技术博文" }}
        </blockquote>

        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in value"
            :key="index"
            :placement="'bottom'"
            :size="'large'"
            :timestamp="common.dateFormatToDay(item.insertTime)"
          >
            {{ item.title }}
            <i
              class="el-icon-lock"
              v-if="item.alone === 1"
              style="color: red; margin-left: 15px"
            ></i>
            <el-button
              size="small"
              round
              style="float: right; margin-top: 6px"
              @click="volRead(item.id, item.alone)"
              >查看</el-button
            >
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      archivesData: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let that = this;
      this.$axios
        .get("/blog/getAllArticle", {})
        .then(function (resp) {
          that.archivesData = resp.data;
        });
    },
    volRead(id, alone) {
      //1  是加密文章
      if (alone === 1) {
        this.$prompt("请输入密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValidator: (value) => {
            //自定义校验
            if (!value) {
              return "请输入密码";
            }
          },
        }).then(
          ({ value }) => {
            this.veryRead(id, value);
          },
          () => {} //取消输入
        );
      } else {
        this.$router.push("/readBlog?flag=false&data=" + id);
      }
    },
    veryRead(aid, password) {
      let that = this;
      this.$axios
        .get("/blogApi/verifyArticlePassword", {
          params: {
            aid: aid,
            password: password,
          },
        })
        .then(function (resp) {
          if (resp.data.code === 200) {
            that.$router.push("/readBlog?flag=true&data=" + resp.data.data);
          } else {
            that.$message({
              showClose: true,
              message: resp.data.msg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style >
#showArchives {
  padding: 20px;
  margin-bottom: 26px;
  /*background: white;*/
  background-color: rgb(252 252 255 / 21%);
  box-shadow: rgb(77 77 77 / 17%) 0px 0px 20px 10px;
  border-radius: 15px;
}

.el-timeline-item__content {
  font-size: 18px;
}
.el-timeline-item__timestamp {
  font-size: 10px;
}
.el-timeline li {
  transition: all 0.28s;
}
.el-timeline li:hover {
  /* cursor: pointer; */
  /* background: rgb(243, 243, 243); */
  border-radius: 25px;
  padding: 5px;
  margin: 5px;
  box-shadow: rgb(77 77 77 / 17%) 0px 0px 10px 10px;
}
</style>